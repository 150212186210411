

<template>
  <span class="ag-icon ag-icon-grip">{{ label }}</span>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SelectorRowSelectorCellRenderer',
  data() {
    return {
      value: null,
      mouseUpEventHandler: null,
      mouseDownEventHandler: null,
      // tabName: null //Used by StaffSelectorModalForAdmin
    }
  },
  beforeMount() {
    if (this.params.isReadOnly == true) {
      this.params.eGridCell.classList.add('read-only');
    }
    this.value = this.params && this.params.value !== -1? this.params.value: null;
  },
  mounted() {
    this.mouseUpEventHandler = (event) => {
      let rowIndex = null;
      if (this.params.eGridCell != null && this.params.eGridCell.parentElement != null) {
        rowIndex = this.params.eGridCell.parentElement.getAttribute('row-index');
      }
      if (rowIndex != null && rowIndex.trim().length == 0) {
        rowIndex = null;
      }

      this.params.context.componentParent.rowSelectorMouseUp({ 
        ctrlKey: event.ctrlKey != true? false : true
        , shiftKey: event.shiftKey != true? false : true
        , rowIndex: rowIndex != null? parseInt(rowIndex) : null
        , optionalParams: this.params.optionalParams != null?  JSON.parse(JSON.stringify(this.params.optionalParams)) : null
      });
    }
    this.params.eGridCell.addEventListener('mouseup', this.mouseUpEventHandler);

    this.mouseDownEventHandler = (/**event*/) => {
      let rowIndex = null;
      if (this.params.eGridCell != null && this.params.eGridCell.parentElement != null) {
        rowIndex = this.params.eGridCell.parentElement.getAttribute('row-index');
      }
      if (rowIndex != null && rowIndex.trim().length == 0) {
        rowIndex = null;
      }
      this.params.context.componentParent.rowSelectorMouseDown(rowIndex, this.params.optionalParams != null?  JSON.parse(JSON.stringify(this.params.optionalParams)) : null);
    }
    this.params.eGridCell.addEventListener('mousedown', this.mouseDownEventHandler);
  },
  beforeDestroy() {
    if (this.mouseUpEventHandler != null) {
      this.params.eGridCell.removeEventListener('mouseup', this.mouseUpEventHandler);
      this.mouseUpEventHandler = null;
    }

    if (this.mouseDownEventHandler != null) {
      this.params.eGridCell.removeEventListener('mousedown', this.mouseDownEventHandler);
      this.mouseDownEventHandler = null;
    }
  },
  computed: {
    label() {
      return '';
    }
  }
})
</script>

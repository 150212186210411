var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "span",
        { attrs: { id: _vm.randomId } },
        [
          _c("font-awesome-icon", {
            style: { color: _vm.color },
            attrs: { icon: ["fas", "circle"] },
          }),
          _vm._v(" " + _vm._s(_vm.status) + " "),
        ],
        1
      ),
      _c("b-popover", {
        attrs: {
          target: _vm.randomId,
          placement: "auto",
          triggers: "hover",
          content: _vm.status,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
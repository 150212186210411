<template>
  <div>
    <span :id="randomId">
      <font-awesome-icon :icon="['fas','circle']" :style="{ color: color}" />
      {{ status }}
    </span>
    <b-popover
      :target="randomId"
      placement="auto"
      triggers="hover"
      :content="status">
    </b-popover>
  </div>
</template>

<script>
import Vue from 'vue';
import { strRandom } from '@/helpers';

export default Vue.extend({
  name: 'TaskAlertStatusCellRenderer',
  data() {
    return {
      status: null,
      color: null
    }
  },
  created() {
    this.randomId = `status_${strRandom(8)}`;
    this.status = this.params.value;
    this.color = this.params.data.color;
  },
  methods: {
  }
});
</script>
